<template>
  <v-row no-gutters>
    <v-col>
      <div id="admin-list">
        <!-- app drawer -->

        <admin-list-add-new
          v-model="isAddNewAdminSidebarActive"
          :status-options="statusOptions"
          :customer-group-options="customerGroupOptions"
          :pricelist-options="pricelistOptions"
          :role-options="roleOptions"
          :user-item="userItem"
          :user-type="userType"
          @refetch-data="fetchAdmins"
        ></admin-list-add-new>

        <admin-list-filter
          v-model="isFilterAdminSidebarActive"
          :status-options="statusOptions"
          :customer-group-options="customerGroupOptions"
          :pricelist-options="pricelistOptions"
          :role-options="roleOptions"
          @filter-data="filterData"
        >
        </admin-list-filter>

        <!-- list filters -->

        <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
        >
          <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? (isDark ? '#3b355a' : 'transparent') : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
          >
            <v-toolbar-title class="text-h6 font-weight-medium">
              <v-icon large left>
                {{ icons.mdiAccountTie }}
              </v-icon>
              Yöneticiler
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn plain text small @click="mailSend">
              <v-icon left>
                {{ icons.mdiDownload }}
              </v-icon>
              İndir
            </v-btn>
            <v-btn plain text small @click.stop="isFilterAdminSidebarActive = !isFilterAdminSidebarActive">
              <v-badge v-if="isFilterNumber" overlap color="tertiary" dot offset-x="15" offset-y="5">
                <v-icon left>
                  {{ icons.mdiFilter }}
                </v-icon>
              </v-badge>
              <v-icon v-else left>
                {{ icons.mdiFilter }}
              </v-icon>
              Filitre
            </v-btn>
            <v-btn
              class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text"
              depressed
              x-large
              color="secondary"
              @click="userAdd()"
            >
              <span>Yönetici Ekle</span>
            </v-btn>
          </v-toolbar>
          <v-expansion-panels v-else v-model="panel" flat tile>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div class="text-body-2 font-weight-medium">
                  <v-icon left>
                    {{ icons.mdiAccountTie }}
                  </v-icon>
                  Yöneticiler
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-divider></v-divider>
                <v-list class="pa-0" color="grey">
                  <v-list-item @click="userAdd()">
                    <v-list-item-content>
                      <v-list-item-title>Yönetici Ekle</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click.stop="isFilterAdminSidebarActive = !isFilterAdminSidebarActive">
                    <v-list-item-content>
                      <v-list-item-title>Filitre</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon :color="isFilterNumber ? 'tertiary' : null">
                        {{ icons.mdiFilter }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click.stop="isFilterAdminSidebarActive = !isFilterAdminSidebarActive">
                    <v-list-item-content>
                      <v-list-item-title>İndir</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>
                        {{ icons.mdiDownload }}
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- table -->
          <v-data-table
            v-model="selectedRows"
            :headers="tableColumns"
            :items="adminListTable"
            :options.sync="options"
            :items-per-page="15"
            fixed-header
            :height="dataTableHeight"
            :server-items-length="totalAdminListTable"
            :loading="loading"
          >
            <!-- Text -->
            <template #[`item.uid`]="{ item }">
              <div class="text-caption">
                {{ item.uid }}
              </div>
            </template>

            <template #[`item.adsoyad`]="{ item }">
              {{ item.adsoyad }}
            </template>
            <template #[`item.username`]="{ item }">
              <router-link
                :to="{ name: 'user-view', params: { id: item.uid } }"
                class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.username }}
              </router-link>
            </template>
            <template #[`item.aktif`]="{ item }">
              <v-chip :color="resolveAdminStatusVariant(item.aktif)" link outlined small>
                {{ statusFind(item.aktif, 'title') }}
              </v-chip>
            </template>
            <template #[`item.son_giris`]="{ item }">
              <div v-if="item.son_giris === null"></div>
              <timeago v-else class="text-caption" :datetime="item.son_giris"></timeago>
            </template>
            <template #[`item.kayit_tarihi`]="{ item }">
              <div class="text-caption">
                {{ item.kayit_tarihi | moment('Do MMMM YYYY') }}
              </div>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="userEdit(item)">
                Düzenle
              </v-btn>

              <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed color="error" x-small plain v-bind="attrs" @click="adminDelete(item)" v-on="on">
                    <v-icon small>
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Sil</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { convertArrayToObject } from '@/utils'
import useAppConfig from '@core/@app-config/useAppConfig'
import { avatarText } from '@core/utils/filter'
import {
  mdiAccount,
  mdiAccountPlus,
  mdiAccountTie,
  mdiArrowLeft,
  mdiDelete,
  mdiDotsHorizontal,
  mdiDownload,
  mdiExportVariant,
  mdiEye,
  mdiFileExcelBox,
  mdiFilePdfBox,
  mdiFilter,
  mdiFilterOff,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiMagnify,
  mdiMessage,
  mdiPencil,
  mdiPlus,
  mdiPlusThick,
  mdiSend,
  mdiShare,
  mdiShield,
  mdiShieldHalfFull,
  mdiSquareEditOutline,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import adminStoreModule from '../adminStoreModule'
import AdminListAddNew from './AdminListAddNew.vue'
import AdminListFilter from './AdminListFilter.vue'
import useAdminsList from './useAdminsList'

export default {
  components: {
    AdminListAddNew,
    AdminListFilter,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-admin'
    const { isDark } = useAppConfig()

    // this won't work because we are passing a number to watch()

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, adminStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      fetchCustomerGroup()
      // eslint-disable-next-line no-use-before-define
      fetchPricelist()
      // eslint-disable-next-line no-use-before-define
      fetchRole()
    })

    const {
      adminListTable,
      tableColumns,
      searchQuery,
      customerGroupFilter,
      cariKodFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      planFilter,
      idFilter,
      statusFilter,
      totalAdminListTable,
      loading,
      options,
      adminTotalLocal,
      selectedRows,
      customerGroupOptions,
      pricelistOptions,
      roleOptions,
      fetchAdmins,
      fetchCustomerGroup,
      fetchPricelist,
      fetchRole,
      resolveAdminRoleVariant,
      statusOptions,
      resolveAdminStatusVariant,
      resolveAdminTotalIcon,
    } = useAdminsList()

    const isAddNewAdminSidebarActive = ref(false)
    const isFilterNumber = ref(0)
    const isFilterAdminSidebarActive = ref(false)

    const statusFind = (value, select) => statusOptions.find(item => item.value === value)[select]
    const userType = ref('add')
    const userItem = ref({})

    const userEdit = item => {
      userItem.value = item
      userType.value = 'edit'
      isAddNewAdminSidebarActive.value = true
    }
    const userAdd = () => {
      userItem.value = {}
      userType.value = 'add'
      isAddNewAdminSidebarActive.value = true
    }
    const mailSend = async () => {
      const { value: email } = await Vue.swal({
        icon: 'info',
        title: 'E-Posta Gönder',
        text: 'Göndermek istediğiniz e-posta adresini yazın',
        input: 'email',
        // eslint-disable-next-line consistent-return
        inputValidator: value => {
          if (!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            return 'Geçersiz e-posta adresi'
          }
        },
      })

      if (email) {
        Vue.swal({ icon: 'success', text: 'E-Posta gönderildi' })
      }
    }

    const adminDelete = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('app-admin/deleteAdmin', {
              method: 'deleteMember',
              id: item.uid,
            })
            .then(() => {
              Vue.swal({
                title: 'Silindi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              fetchAdmins()
            })
        }
      })
    }

    const adminsDelete = () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })

      Vue.swal({
        title: 'Emin misin?',
        text: 'Toplu silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          Vue.swal({
            title: 'Silindi',
            text: 'İşlem başarılı',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          })
          selectedRows.value = []
          fetchAdmins()
        }
      })
    }

    const adminsRole = async () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })
      const inputOptions = convertArrayToObject(customerGroupOptions.value, 'value', 'title')
      const { value: role } = await Vue.swal({
        icon: 'question',
        title: 'Rol Değiştir',
        text: 'Toplu rol değiştirme değeri seçin',
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Rol Seçin',
        showCancelButton: true,
      })

      if (role) {
        Vue.swal({
          title: 'Değiştirildi',
          text: 'İşlem başarılı',
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        selectedRows.value = []
        fetchAdmins()
      }
    }

    const adminsStatus = async () => {
      const IDs = []
      selectedRows.value.forEach(item => {
        IDs.push(item.id)
      })

      const inputOptions = convertArrayToObject(statusOptions, 'value', 'title')
      const { value: role } = await Vue.swal({
        icon: 'question',
        title: 'Durum Değiştir',
        text: 'Toplu durum değiştirme değeri seçin',
        input: 'select',
        inputOptions,
        inputPlaceholder: 'Durum Seçin',
        showCancelButton: true,
      })

      if (role) {
        Vue.swal({
          title: 'Değiştirildi',
          text: 'İşlem başarılı',
          icon: 'success',
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        selectedRows.value = []
        fetchAdmins()
      }
    }

    const filterData = item => {
      idFilter.value = item.uid || null
      statusFilter.value = item.aktif || null
      customerGroupFilter.value = item.cust_group || null
      cariKodFilter.value = item.cari_kod || null
      nameFilter.value = item.adsoyad || null
      usernameFilter.value = item.username || null
      emailFilter.value = item.email || null
      updatedFilter.value = item.updated || null

      let i = 0
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          i += 1
        }
      }
      isFilterNumber.value = i
    }

    return {
      mailSend,
      isFilterNumber,
      filterData,
      isDark,
      adminListTable,
      tableColumns,
      searchQuery,
      customerGroupFilter,
      cariKodFilter,
      nameFilter,
      usernameFilter,
      emailFilter,
      customerFilter,
      updatedFilter,
      planFilter,
      idFilter,
      statusFilter,
      totalAdminListTable,
      customerGroupOptions,
      pricelistOptions,
      roleOptions,
      statusOptions,
      loading,
      options,
      adminTotalLocal,
      isAddNewAdminSidebarActive,
      isFilterAdminSidebarActive,
      selectedRows,
      statusFind,
      avatarText,
      resolveAdminRoleVariant,
      resolveAdminStatusVariant,
      resolveAdminTotalIcon,
      fetchAdmins,
      fetchCustomerGroup,
      fetchPricelist,
      fetchRole,
      adminDelete,
      adminsDelete,
      adminsRole,
      adminsStatus,
      userItem,
      userType,
      userEdit,
      userAdd,

      // icons
      icons: {
        mdiDotsHorizontal,
        mdiFileExcelBox,
        mdiFilePdfBox,
        mdiMessage,
        mdiWeatherNight,
        mdiWeatherSunny,
        mdiSquareEditOutline,
        mdiPencil,
        mdiAccountPlus,
        mdiDelete,
        mdiAccount,
        mdiPlus,
        mdiExportVariant,
        mdiAccountTie,
        mdiEye,
        mdiMagnify,
        mdiShare,
        mdiFilter,
        mdiFullscreen,
        mdiFullscreenExit,
        mdiShield,
        mdiSend,
        mdiArrowLeft,
        mdiShieldHalfFull,
        mdiFilterOff,
        mdiPlusThick,
        mdiDownload,
      },
    }
  },
  data() {
    return {
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 48 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
